import { useState, useEffect, Fragment, useRef, useMemo } from 'react';
import {
    UserPlusIcon,
    CloudArrowUpIcon,
} from '@heroicons/react/20/solid';
import { Dialog, Transition, Switch } from '@headlessui/react'
import readXlsxFile from 'read-excel-file';

//services
import MeetingAdminService from "../../services/meeting.admin.service";
import UserAdminService from '../../services/user.admin.service';
import { toast } from 'react-toastify';

//components
import Spinner from '../../components/ui/spinner.component';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function AdminUsersView() {
    const [users, setUsers] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);

    const [excelModalOpen, setExcelModalOpen] = useState(false)
    const excelUpload = useRef(null);
    const [uploading, setUploading] = useState(false);
    const [excelRows, setExcelRows] = useState([]);
    const [excelColumns, setExcelColumns] = useState([]);

    const [excelName, setExcelName] = useState();
    const [excelEmail, setExcelEmail] = useState();
    const [excelPhone, setExcelPhone] = useState();
    const [excelLanguage, setExcelLanguage] = useState();

    const [reloadUsers, setReloadUsers] = useState(0);
    const [errorLogs, setErrorLogs] = useState([]);

    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [language, setLanguage] = useState("nl");
    const [admin, setAdmin] = useState(false);

    useEffect(() => {
        document.title = "Assemblo - Admin - Gebruikers"
        MeetingAdminService.getAllUsers()
            .then(response => {
                console.log(response.data);
                setUsers(response.data);
            })
            .catch(err => {
                console.log(err);
            }
            );
    }, [reloadUsers]);

    function addUser() {
        if (firstname && lastname && email && phone && language) {
            UserAdminService.addUser(
                {
                    firstname: firstname,
                    lastname: lastname,
                    email: email,
                    telephone: phone,
                    language: language,
                    admin: admin
                }
            )
                .then(response => {
                    setUsers([...users, response.data]);
                    closeModal();
                })
                .catch(err => {
                    console.log(err);
                }
                );

        } else {
            toast.error("Vul alle velden in.");
        }
    }

    function deleteUser(id) {
        UserAdminService.deleteUser(id)
            .then(response => {
                setUsers(users.filter(user => user.id !== id));
            })
            .catch(err => {
                console.log(err);
            }
            );
    }

    function closeModal() {
        setModalOpen(false);
        setAdmin(false);
        setFirstname("");
        setLastname("");
        setEmail("");
        setPhone("");
    }

    function handleExcelUpload(e) {
        readXlsxFile(e.target.files[0]).then((rows) => {
            setExcelRows(rows);
            setExcelColumns(rows[0]);
        })
            .catch(err => {
                toast.error(err);
                setExcelRows([]);
                setExcelColumns([]);
            }
            );
    }

    function uploadExcel() {
        if (excelRows.length > 0) {
            if (excelName && excelEmail && excelPhone && excelLanguage && (excelName !== excelEmail && excelName !== excelPhone && excelEmail !== excelPhone && excelName !== excelLanguage && excelEmail !== excelLanguage && excelPhone !== excelLanguage)) {
                setUploading(true);
                const users = [];
                for (let i = 1; i < excelRows.length; i++) {
                    users.push({
                        name: excelRows[i][excelName],
                        email: excelRows[i][excelEmail],
                        telephone: excelRows[i][excelPhone],
                        language: excelRows[i][excelLanguage],
                        admin: false
                    });
                }
                UserAdminService.uploadExcel(users)
                    .then(response => {
                        toast.success("Gebruikers zijn toegevoegd.");
                        setReloadUsers(reloadUsers + 1);
                        setUploading(false);
                        if (response.data.length > 0) {
                            setErrorLogs(response.data);
                        } else {
                            cancelExcelUpload();
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    });
            } else {
                toast.error("Niet alle kolommen zijn geselecteerd of er zijn dubbele kolommen geselecteerd.");
            }
        } else {
            toast.error("Er is geen excel bestand geupload.");
        }
    }

    function cancelExcelUpload() {
        setExcelModalOpen(false);
        setExcelRows([]);
        setExcelColumns([]);
        setExcelName();
        setExcelEmail();
        setExcelPhone();
        setErrorLogs([]);
        excelUpload.current.files = null;
    }

    function updateMail(mail, id, update) {
        setUsers(users => users.map(user => {
            if (user.id === id) {
                user.email = mail;
            }
            return user;
        }));
        if (update) {
            UserAdminService.updateMail(id, mail)
                .then(response => {
                    setReloadUsers(reloadUsers + 1);
                    toast.success("E-mailadres is aangepast.");
                })
                .catch(err => {
                    console.log(err);
                    toast.error("Er is iets misgegaan bij het aanpassen van het e-mailadres.");
                }
                );
        }
    }

    // SORTING AND FILTERING LOGIC
    const [sortColumn, setSortColumn] = useState('firstname');
    const [sortDirection, setSortDirection] = useState('asc');
    const [searchTerm, setSearchTerm] = useState('');
  
    const handleSort = (column) => {
      if (column === sortColumn) {
        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
      } else {
        setSortColumn(column);
        setSortDirection('asc');
      }
    };
  
    const handleSearch = (e) => {
      setSearchTerm(e.target.value);
    };
  
    const filteredUsers = useMemo(() => {
      const searchTerms = searchTerm.toLowerCase().split(' ').filter(Boolean);
  
      return users.filter(user => {
        const fullName = `${user.firstname.toLowerCase()} ${user.lastname.toLowerCase()}`;
        const userEmail = user.email.toLowerCase();
        const userPhone = user.telephone.toLowerCase();
  
        return searchTerms.every(term =>
          fullName.includes(term) ||
          userEmail.includes(term) ||
          userPhone.includes(term)
        );
      });
    }, [users, searchTerm]);
  
    const sortedUsers = useMemo(() => {
      const sorted = [...filteredUsers].sort((a, b) => {
        if (a[sortColumn] < b[sortColumn]) return sortDirection === 'asc' ? -1 : 1;
        if (a[sortColumn] > b[sortColumn]) return sortDirection === 'asc' ? 1 : -1;
        return 0;
      });
      return sorted;
    }, [filteredUsers, sortColumn, sortDirection]);

    return (
        <main className="flex-1">
            <div className="py-6">
                <div className="mx-auto px-4 sm:px-6 lg:px-8 sm:flex sm:items-center sm:justify-between">
                    <h1 className="text-2xl font-semibold text-gray-900">Gebruikers</h1>
                    <div className="mt-3 sm:mt-0 sm:ml-4">
                        <button
                            type="button"
                            onClick={(e) => setExcelModalOpen(true)}
                            className="inline-flex items-center rounded-md border border-transparent bg-indigo-100 px-4 py-2 text-sm font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mr-2"
                        >
                            <UserPlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                            Excel uploaden
                        </button>

                        <button
                            type="button"
                            onClick={(e) => setModalOpen(true)}
                            className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                            <UserPlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                            Nieuwe gebruiker toevoegen
                        </button>
                    </div>
                </div>
                <div className="mx-auto px-4 sm:px-6 md:px-8">
                    <p>Hieronder een lijst van alle Assemblo-gebruikers.</p>
                    <br />

                    {/* table */}
                    <div className="">
                        <div className="mt-8 flex flex-col">
                            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                        <div className="p-4">
                                            <input
                                                type="text"
                                                placeholder="Zoeken..."
                                                value={searchTerm}
                                                onChange={handleSearch}
                                                className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                            />
                                        </div>
                                        <table className="min-w-full divide-y divide-gray-300">
                                            <thead className="bg-gray-100">
                                                <tr>
                                                    <th
                                                        scope="col"
                                                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 cursor-pointer"
                                                        onClick={() => handleSort('firstname')}
                                                    >
                                                        Naam {sortColumn === 'firstname' && (sortDirection === 'asc' ? '↑' : '↓')}
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                                                        onClick={() => handleSort('email')}
                                                    >
                                                        E-mail {sortColumn === 'email' && (sortDirection === 'asc' ? '↑' : '↓')}
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                                                        onClick={() => handleSort('telephone')}
                                                    >
                                                        Telefoon {sortColumn === 'telephone' && (sortDirection === 'asc' ? '↑' : '↓')}
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                                                        onClick={() => handleSort('lang')}
                                                    >
                                                        Taal {sortColumn === 'lang' && (sortDirection === 'asc' ? '↑' : '↓')}
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                                                        onClick={() => handleSort('admin')}
                                                    >
                                                        Rol {sortColumn === 'admin' && (sortDirection === 'asc' ? '↑' : '↓')}
                                                    </th>
                                                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                        <span className="sr-only">Edit</span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white">
                                                {sortedUsers.map((person, personIdx) => (
                                                    <tr key={person.id} className={personIdx % 2 === 0 ? undefined : 'bg-gray-50'}>
                                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                            {person.firstname} {person.lastname}
                                                        </td>
                                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                            <input
                                                                className="w-full border-none bg-transparent rounded-md"
                                                                type="text"
                                                                value={person.email}
                                                                onChange={(e) => updateMail(e.target.value, person.id, false)}
                                                                onBlur={(e) => updateMail(e.target.value, person.id, true)}
                                                                onKeyDown={(e) => e.key === 'Enter' && updateMail(e.target.value, person.id, true)}
                                                            />
                                                        </td>
                                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.telephone}</td>
                                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                            {person.lang === "nl" ? "Nederlands" : person.lang === "fr" ? "Frans" : person.lang === "en" ? "Engels" : "Onbekend"}
                                                        </td>
                                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                            {person.admin ? "Administrator" : "Investeerder"}
                                                        </td>
                                                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                            <a onClick={() => deleteUser(person.id)} className="text-indigo-600 hover:text-indigo-900 cursor-pointer select-none">
                                                                Verwijderen<span className="sr-only">, {person.firstname} {person.lastname}</span>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {/* MODAL ADD USER */}
            <Transition.Root show={modalOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={setModalOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                    <div>

                                        <div className="mt-1">
                                            <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                                Gebruiker toevoegen
                                            </Dialog.Title>
                                            <div className="mt-2">
                                                <p className="text-sm text-gray-500">
                                                    Vul onderstaande velden in om een nieuwe gebruiker toe te voegen.
                                                    De gebruiker ontvangt hiervan geen notificatie, maar kan inloggen met de magische link.
                                                </p>
                                            </div>
                                            <div>
                                                <form className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                                                    <div>
                                                        <label htmlFor="firstname" className="block text-sm font-medium text-gray-700">
                                                            Voornaam
                                                        </label>
                                                        <div className="mt-1">
                                                            <input

                                                                type="text"
                                                                name="firstname"
                                                                id="firstname"
                                                                autoComplete="given-name"
                                                                className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                                                onChange={e => (setFirstname(e.target.value))}
                                                                value={firstname}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <label htmlFor="firstname" className="block text-sm font-medium text-gray-700">
                                                            Achternaam
                                                        </label>
                                                        <div className="mt-1">
                                                            <input

                                                                type="text"
                                                                name="firstname"
                                                                id="firstname"
                                                                autoComplete="given-name"
                                                                className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                                                onChange={e => (setLastname(e.target.value))}
                                                                value={lastname}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-span-2">
                                                        <label htmlFor="firstname" className="block text-sm font-medium text-gray-700">
                                                            E-mailadres
                                                        </label>
                                                        <div className="mt-1">
                                                            <input
                                                                type="text"
                                                                name="firstname"
                                                                id="firstname"
                                                                autoComplete="given-name"
                                                                className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                                                onChange={e => (setEmail(e.target.value))}
                                                                value={email}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-span-2">
                                                        <label htmlFor="firstname" className="block text-sm font-medium text-gray-700">
                                                            Telefoonnummer
                                                        </label>
                                                        <div className="mt-1">
                                                            <input
                                                                type="text"
                                                                name="firstname"
                                                                id="firstname"
                                                                autoComplete="given-name"
                                                                className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                                                onChange={e => (setPhone(e.target.value))}
                                                                value={phone}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-span-2">
                                                        <label htmlFor="firstname" className="block text-sm font-medium text-gray-700">
                                                            Taal
                                                        </label>
                                                        <select
                                                            id="language"
                                                            name="language"
                                                            autoComplete="language"
                                                            className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                                            onChange={e => (setLanguage(e.target.value))}
                                                            value={language}
                                                        >
                                                            <option value="nl">Nederlands</option>
                                                            <option value="fr">Frans</option>
                                                            <option value="en">Engels</option>
                                                        </select>
                                                    </div>
                                                    <Switch.Group as="div" className="flex items-center justify-between col-span-2">
                                                        <span className="flex flex-grow flex-col">
                                                            <Switch.Label as="span" className="text-sm font-medium text-gray-900" passive>
                                                                Administrator
                                                            </Switch.Label>
                                                            <Switch.Description as="span" className="text-sm text-gray-500">
                                                                Pas op, deze gebruiker krijgt toegang tot alle gegevens.
                                                            </Switch.Description>
                                                        </span>
                                                        <Switch
                                                            checked={admin}
                                                            onChange={setAdmin}
                                                            className={classNames(
                                                                admin ? 'bg-indigo-600' : 'bg-gray-200',
                                                                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                                                            )}
                                                        >
                                                            <span
                                                                aria-hidden="true"
                                                                className={classNames(
                                                                    admin ? 'translate-x-5' : 'translate-x-0',
                                                                    'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                                                )}
                                                            />
                                                        </Switch>
                                                    </Switch.Group>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-12 sm:mt-12 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                        <button
                                            type="button"
                                            className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                                            onClick={() => addUser()}
                                        >
                                            Aanmaken
                                        </button>
                                        <button
                                            type="button"
                                            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                                            onClick={() => closeModal()}
                                        >
                                            Annuleer
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>

            {/* MODAL EXCEL UPLOAD */}
            <Transition.Root show={excelModalOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={setExcelModalOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                        <div className="sm:flex sm:items-start">
                                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                                                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                                    Gebruikers importeren van Excel
                                                </Dialog.Title>
                                                {errorLogs.length == 0 ?
                                                    <div className="mt-2">
                                                        <p className="text-sm text-gray-500">
                                                            Laad een excel bestand met gebruikers in.<br />
                                                            <br />
                                                            Deze Excel moet aan de volgende voorwaarden voldoen:<br />
                                                            <ul className="list-decimal list-inside">
                                                                <li>Het bestand is een .xlsx bestand.</li>
                                                                <li>De eerste rij is de header</li>
                                                            </ul>
                                                        </p>

                                                        <div className="mt-6">
                                                            {excelColumns.length == 0 ? (
                                                                <label
                                                                    className="flex justify-center w-full h-32 px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none">
                                                                    <span class="flex items-center space-x-2 text-gray-400">
                                                                        <CloudArrowUpIcon className="w-6 h-6" />
                                                                        <span class="font-medium">
                                                                            Sleep je Excel-bestand hierheen of&nbsp;
                                                                            <span class="text-indigo-600">selecteer</span>.
                                                                        </span>
                                                                    </span>
                                                                    <input
                                                                        type="file"
                                                                        name="excel"
                                                                        accept=".xlsx"
                                                                        onChange={(e) => handleExcelUpload(e)}
                                                                        ref={excelUpload}
                                                                        className="hidden"
                                                                    />
                                                                </label>
                                                            ) : (
                                                                <p
                                                                    className="text-md text-gray-500"
                                                                >
                                                                    Je hebt een bestand geupload met {excelRows.length} rijen.
                                                                </p>)}
                                                        </div>


                                                        {excelColumns.length > 0 ?
                                                            <div className='mt-4'>
                                                                <div className='flex'>
                                                                    <div className='w-1/2'>
                                                                        <p
                                                                            className="text-sm text-gray-500 mt-2 font-bold"
                                                                        >
                                                                            Naam
                                                                        </p>
                                                                    </div>
                                                                    <div className='w-1/2'>
                                                                        <select
                                                                            name="name"
                                                                            id="name"
                                                                            className="w-full px-3 py-2 text-base text-gray-700 placeholder-gray-500 border rounded-lg appearance-none focus:shadow-outline-blue focus:outline-none focus:border-blue-300"
                                                                            onChange={(e) => setExcelName(e.target.value)}
                                                                        >
                                                                            <option value="0" selected disabled>Selecteer een kolom</option>
                                                                            {excelColumns ? excelColumns.map((row, index) => (
                                                                                <option key={index} value={index}>{row}</option>
                                                                            )) : null}
                                                                        </select>
                                                                    </div>
                                                                </div>

                                                                <div className='flex mt-1'>
                                                                    <div className='w-1/2'>
                                                                        <p
                                                                            className="text-sm text-gray-500 mt-2 font-bold"
                                                                        >
                                                                            E-mailadres
                                                                        </p>
                                                                    </div>
                                                                    <div className='w-1/2'>
                                                                        <select
                                                                            name="name"
                                                                            id="name"
                                                                            className="w-full px-3 py-2 text-base text-gray-700 placeholder-gray-500 border rounded-lg appearance-none focus:shadow-outline-blue focus:outline-none focus:border-blue-300"
                                                                            onChange={(e) => setExcelEmail(e.target.value)}
                                                                        >
                                                                            <option value="0" selected disabled>Selecteer een kolom</option>
                                                                            {excelColumns ? excelColumns.map((row, index) => (
                                                                                <option key={index} value={index}>{row}</option>
                                                                            )) : null}
                                                                        </select>
                                                                    </div>
                                                                </div>

                                                                <div className='flex mt-1'>
                                                                    <div className='w-1/2'>
                                                                        <p
                                                                            className="text-sm text-gray-500 mt-2 font-bold"
                                                                        >
                                                                            Telefoonnummer
                                                                        </p>
                                                                    </div>
                                                                    <div className='w-1/2'>
                                                                        <select
                                                                            name="name"
                                                                            id="name"
                                                                            className="w-full px-3 py-2 text-base text-gray-700 placeholder-gray-500 border rounded-lg appearance-none focus:shadow-outline-blue focus:outline-none focus:border-blue-300"
                                                                            onChange={(e) => setExcelPhone(e.target.value)}
                                                                        >
                                                                            <option value="0" selected disabled>Selecteer een kolom</option>

                                                                            {excelColumns ? excelColumns.map((row, index) => (
                                                                                <option key={index} value={index}>{row}</option>
                                                                            )) : null}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className='flex mt-1'>
                                                                    <div className='w-1/2'>
                                                                        <p
                                                                            className="text-sm text-gray-500 mt-2 font-bold"
                                                                        >
                                                                            Taal
                                                                        </p>
                                                                    </div>
                                                                    <div className='w-1/2'>
                                                                        <select
                                                                            name="name"
                                                                            id="name"
                                                                            className="w-full px-3 py-2 text-base text-gray-700 placeholder-gray-500 border rounded-lg appearance-none focus:shadow-outline-blue focus:outline-none focus:border-blue-300"
                                                                            onChange={(e) => setExcelLanguage(e.target.value)}
                                                                        >
                                                                            <option value="0" selected disabled>Selecteer een kolom</option>

                                                                            {excelColumns ? excelColumns.map((row, index) => (
                                                                                <option key={index} value={index}>{row}</option>
                                                                            )) : null}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : null}
                                                    </div>
                                                    :
                                                    <div>
                                                        <div className='mt-2'>
                                                            <p className="text-sm text-gray-500">
                                                                Er zijn enkele fouten gevonden bij het uploaden van de gebruikers.<br />
                                                                <br />
                                                                Gebruikers waar geen fouten gevonden zijn zijn succesvol geupload. Probeer het opnieuw met een bestand waar geen fouten in zitten.
                                                            </p>
                                                            <ul className='mt-6 text-sm text-gray-400'>
                                                                {errorLogs.map((row, index) => (
                                                                    <li className='mt-2'>{index + 1}. {row}</li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>}
                                            </div>
                                        </div>
                                    </div>
                                    {uploading ?
                                        <div className="mt-2 mb-4">
                                            <Spinner />
                                        </div>
                                        :
                                        <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                            {errorLogs.length == 0 ? <button
                                                type="button"
                                                className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                                                onClick={() => uploadExcel()}
                                            >
                                                Upload
                                            </button> : null}
                                            <button
                                                type="button"
                                                className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                                onClick={() => cancelExcelUpload()}
                                            >
                                                Sluiten
                                            </button>
                                        </div>}
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </main>
    )
}