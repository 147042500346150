import api from "./api/api";
import TokenService from "./api/token";

class UserAdminService { 
    addUser(user) {
      return api.post("/admin/users/add/", user);
    }

    deleteUser(id) {
      return api.delete("/admin/users/delete/" + id + "/");
    }

    uploadExcel(rows) {
      return api.post("/admin/users/upload/users/", {
        users: rows,
      });
    }

    uploadExcelInvites(id, rows) {
      return api.post("/admin/users/upload/invites/", {
        meeting_id: id,
        invites: rows,
      });
    }

    updateMail(id, mail) {
      return api.post("/admin/users/update/mail/", {
        id: id,
        mail: mail,
      });
    }

}

export default new UserAdminService();