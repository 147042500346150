import React, { useEffect, useState, useContext, Fragment, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Menu, Transition, Dialog } from '@headlessui/react'
import {
    ArrowPathIcon,
    ChevronDownIcon,
    TrashIcon,
    CloudArrowUpIcon,
    RectangleGroupIcon,
} from '@heroicons/react/20/solid'
import { DocumentMagnifyingGlassIcon, ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import readXlsxFile from 'read-excel-file';

//import services
import MeetingService from "../../../services/meeting.service";
import { socket, SocketContext } from "../../../services/socket.service";
import SignService from '../../../services/sign.service';
import meetingAdminService from '../../../services/meeting.admin.service';
import userAdminService from '../../../services/user.admin.service';


//import components
import Spinner from '../../../components/ui/spinner.component';
import UserSearch from '../../../components/admin/usersearch.component';



function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function AdminMeetingUsersView() {
    const [invitees, setInvitees] = useState([]);
    const [reloadInvites, setReloadInvites] = useState(0);
    const [viewers, setViewers] = useState([]);
    const [users, setUsers] = useState([]);
    const [signs, setSigns] = useState([]);

    const [reload, setReload] = useState(0);


    const [excelModalOpen, setExcelModalOpen] = useState(false)
    const excelUpload = useRef(null);
    const [uploading, setUploading] = useState(false);
    const [excelRows, setExcelRows] = useState([]);
    const [excelColumns, setExcelColumns] = useState([]);

    const [excelEmail, setExcelEmail] = useState();
    const [excelRSVP, setExcelRSVP] = useState();
    const [excelContract, setExcelContract] = useState();
    const [excelShares, setExcelShares] = useState();

    const [noDocusignContract, setNoDocusignContract] = useState(false)

    const [errorLogs, setErrorLogs] = useState([]);

    const { id } = useParams();
    const socketprovider = useContext(SocketContext);

    useEffect(() => {
        MeetingService.getInvitees(id)
            .then(response => {
                setInvitees(sortData(response.data));
            })
            .catch(err => {
                console.log(err);
            }
            );
    }, [reloadInvites]);

    useEffect(() => {
        meetingAdminService.getAllUsers()
            .then(response => {
                setUsers(
                    response.data.filter(
                        (user) =>
                            !invitees.map(invitee => invitee.user_attend.id)
                                .includes(user.id)
                            && user.admin == false
                    )
                );
            })
            .catch(err => {
                console.log(err);
            }
            );

        SignService.getAdminSigns(id)
            .then(response => {
                console.log(response.data)
                setSigns(response.data);
            })
            .catch(err => {
                console.log(err.message)
                toast.error("Er is iets fout gegaan.");
            });

    }, [reload]);

    useEffect(() => {
        socketprovider.connect("admin");

        socketprovider.socket.on('admin', function (data) {
            setViewers([]);
            for (var i = 0; i < data.length; i++) {
                if (data[i].meeting_id == id) {
                    const viewer = data[i]['value']
                    setViewers(viewers => [...viewers, viewer]);
                }
            }
        });

        socketprovider.socket.on('rsvp', function (data) {
            if (data.meeting_id == id) {
                setReloadInvites(reloadInvites => reloadInvites + 1)
                //temporary fix to reload invites, should adapt the invitees state
            }
        });

        //LIVE COM FOR SIGN
        socketprovider.socket.on('sign', function (data) {
            if (data.envelope_id) {
                setReload(reload => reload + 1);
            }
        });

        //LIVE COM FOR COMMENT
        socketprovider.socket.on('usercomment', function (data) {
            if (data.meeting_id == id) {
                setInvitees(invitees => invitees.map(invitee => {
                    if (invitee.id == data.attend_id) {
                        invitee.comment = data.message;
                    }
                    return invitee;
                }));
            }
        });


        return () => {
            socketprovider.socket.off('admin');
            socketprovider.socket.off('rsvp');
            socketprovider.socket.off('sign');
            socketprovider.socket.off('usercomment');
            socketprovider.disconnect();
        }
    }, []);

    function sortData(data) {
        const sorted =
            [
                ...data.filter(invitee => invitee.rsvp == "online" && invitee.rsvp != "present" && invitee.rsvp != "proxy" && invitee.rsvp != "not" && invitee.rsvp != ""),
                ...data.filter(invitee => invitee.rsvp == "proxy" && invitee.rsvp != "online" && invitee.rsvp != "present" && invitee.rsvp != "not" && invitee.rsvp != ""),
                ...data.filter(invitee => invitee.rsvp == "present" && invitee.rsvp != "online" && invitee.rsvp != "proxy" && invitee.rsvp != "not" && invitee.rsvp != ""),
                ...data.filter(invitee => invitee.rsvp == "not" && invitee.rsvp != "online" && invitee.rsvp != "proxy" && invitee.rsvp != "present" && invitee.rsvp != ""),
                ...data.filter(invitee => invitee.rsvp != "online" && invitee.rsvp != "present" && invitee.rsvp != "not" && invitee.rsvp == ""),
            ]
        return sorted;
    }

    function inviteUser(e) {
        meetingAdminService.inviteUser(id, e.id)
            .then(response => {
                setInvitees(invitees => [...invitees, { id: response.data.id, user_attend: e }]);
                toast.success(e.firstname + " " + e.lastname + " is uitgenodigd voor de AV.");
            }
            )
            .catch(err => {
                toast.error("Er is iets fout gegaan.");
            });
    }

    function deleteUserInvite(id) {
        meetingAdminService.deleteUserInvite(id)
            .then(response => {
                setInvitees(invitees.filter(invitee => invitee.id !== id));
                toast.success("De gebruiker is verwijderd.");
            })
            .catch(err => {
                toast.error("Er is iets fout gegaan.");
            });
    }

    function adminUpdateRSVP(user_id, rsvp) {
        meetingAdminService.updateRSVP(id, user_id, rsvp)
            .then(response => {
                toast.success("De RSVP is aangepast.");
            })
            .catch(err => {
                toast.error("Er is iets fout gegaan.");
            });
    }

    function redirectEnvelope(id, firstname, lastname) {
        if (id == "548795") {
            setNoDocusignContract(true);
        } else {
            SignService.downloadPdf(id)
                // is blob, download to client device
                .then(response => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'document_' + firstname + '_' + lastname + '.pdf');
                    document.body.appendChild(link);
                    link.click();
                })
                .catch(err => {
                    toast.error("Er is iets fout gegaan.");
                });
        }
    }

    function refreshUser(id) {
        meetingAdminService.refreshUser(id)
            .then(response => {
                toast.success(response.data)
            })
            .catch(err => {
                toast.error(err.message)
            })
    }

    function sendMessage(message, attend_id, save_db) {
        console.log(message, attend_id, save_db);
        setInvitees(invitees => invitees.map(invitee => {
            if (invitee.id == attend_id) {
                invitee.comment = message;
            }
            return invitee;
        }));

        socketprovider.socket.emit('usercomment', { message: message, attend_id: attend_id, meeting_id: id, save_db: save_db });
    }


    function handleExcelUpload(e) {
        readXlsxFile(e.target.files[0]).then((rows) => {
            setExcelRows(rows);
            setExcelColumns(rows[0]);
        })
            .catch(err => {
                toast.error(err);
                setExcelRows([]);
                setExcelColumns([]);
            }
            );
    }

    function uploadExcel() {
        if (excelRows.length > 0) {
            if (excelEmail && excelRSVP && excelContract && excelShares && (excelEmail !== excelRSVP) && (excelEmail !== excelContract) && (excelRSVP !== excelContract) && (excelShares !== excelRSVP) && (excelShares !== excelContract)) {
                setUploading(true);
                const invites = [];
                const errors = [];
                for (let i = 1; i < excelRows.length; i++) {
                    if (excelRows[i][excelRSVP] == undefined || excelRows[i][excelRSVP] == "online" || excelRows[i][excelRSVP] == "proxy" || excelRows[i][excelRSVP] == "present" || excelRows[i][excelRSVP] == "not") {
                        const contract = excelRows[i][excelContract] ? excelRows[i][excelContract].length > 0 ? "548795" : "" : "";
                        const rsvp = excelRows[i][excelRSVP] == undefined ? "" : excelRows[i][excelRSVP];
                        const shares = excelRows[i][excelShares] ? excelRows[i][excelShares] : 0;
                        invites.push({
                            email: excelRows[i][excelEmail],
                            rsvp: rsvp,
                            contract: contract,
                            shares: shares
                        });
                    } else {
                        toast.error("De gebruiker " + excelRows[i][excelEmail] + " heeft een ongeldige RSVP waarde.")
                        errors.push("De gebruiker " + excelRows[i][excelEmail] + " heeft een ongeldige RSVP waarde.")
                    }
                }

                if (errors.length == 0) {
                    userAdminService.uploadExcelInvites(id, invites)
                        .then(response => {
                            toast.success("Gebruikers zijn uitgenodigd.");
                            setReload(reload => reload + 1);
                            setUploading(false);
                            if (response.data.length > 0) {
                                setErrorLogs(response.data);
                            } else {
                                cancelExcelUpload();
                                window.location.reload();
                            }
                        })
                        .catch(err => {
                            console.log(err);
                        });
                } else {
                    setErrorLogs(errors);
                    setUploading(false);
                    toast.error("Er zijn fouten gevonden in het bestand. Er werd niemand uitgenodigd.");
                }
            } else {
                toast.error("De kolommen zijn niet correct ingevuld.");
            }
        } else {
            toast.error("Er is geen bestand geselecteerd.");
        }
    }

    function cancelExcelUpload() {
        setExcelModalOpen(false);
        setExcelRows([]);
        setExcelColumns([]);
        setExcelEmail();
        setExcelRSVP();
        setExcelContract();
        setExcelShares();
        setErrorLogs([]);
        excelUpload.current.files = null;
    }

    function adminUpdateShares(attend_id, shares) {
        console.log(id, attend_id, shares);
        meetingAdminService.updateShares(id, attend_id, shares)
            .then(response => {
                toast.success("Het aantal aandelen is aangepast.");
            })
            .catch(err => {
                toast.error("Er is iets fout gegaan.");
            });
    }

    return (
        <main className="flex-1">
            <div className="py-6">
                <div className="flex mx-auto px-4 sm:px-6 md:px-8">
                    <div className="w-1/2">
                        <div>
                            <h1 className="text-2xl font-semibold text-gray-900">Genodigden</h1>
                        </div>
                        <div>
                            <p>Hieronder een lijst van genodigden van deze AV</p>
                        </div>
                    </div>
                    <div className="w-1/2 rounded-lg border-2 border-dashed border-gray-300 px-6 py-6 hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                        <div className="mx-auto">
                            <p>Nodig een gebruiker uit voor deze AV of{" "}
                                <button
                                    className="ml-1 inline-flex items-center rounded border border-transparent bg-indigo-600 px-1.5 py-1 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    onClick={() => setExcelModalOpen(true)}
                                >
                                    uploaden vanaf Excel-bestand
                                </button>
                            </p>
                        </div>
                        <div className="mx-auto my-4">
                            <UserSearch
                                people={
                                    users.filter(user => {
                                        return !invitees.some(invitee => invitee.user_attend.id === user.id)
                                    }
                                    )
                                }
                                inviteUser={inviteUser}
                            />
                        </div>
                    </div>
                </div>

                <div className="mx-auto px-4 sm:px-6 md:px-8">
                    <TableComponent
                        invitees={invitees}
                        setInvitees={setInvitees}
                        viewers={viewers}
                        signs={signs}
                        redirectEnvelope={redirectEnvelope}
                        sendMessage={sendMessage}
                        refreshUser={refreshUser}
                        deleteUserInvite={deleteUserInvite}
                        adminUpdateRSVP={adminUpdateRSVP}
                        adminUpdateShares={adminUpdateShares}
                    />
                </div>
            </div>

            {/* MODAL EXCEL UPLOAD */}
            <Transition.Root show={excelModalOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={setExcelModalOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                        <div className="sm:flex sm:items-start">
                                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                                                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                                    Gebruikers uitnodigen van Excel
                                                </Dialog.Title>
                                                {errorLogs.length == 0 ?
                                                    <div className="mt-2">
                                                        <p className="text-sm text-gray-500">
                                                            Laad een excel bestand met gebruikers in.<br />
                                                            <br />
                                                            Deze Excel moet aan de volgende voorwaarden voldoen:<br />
                                                            <ul className="list-decimal list-inside">
                                                                <li>Het bestand is een .xlsx bestand.</li>
                                                                <li>De eerste rij is de header.</li>
                                                                <li>De header bevat de volgende kolommen: <b>email</b>, <b>RSVP</b> en <b>contract referentie</b>.</li>
                                                                <li>De RSVP kolom bevat enkel volgende waarden: <b>online</b>, <b>proxy</b>, <b>present</b> en <b>not</b>.</li>
                                                                <li>De aantal aandelen kolom is optioneel.</li>
                                                            </ul>
                                                        </p>

                                                        <div className="mt-6">
                                                            {excelColumns.length == 0 ? (
                                                                <label
                                                                    className="flex justify-center w-full h-32 px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none">
                                                                    <span class="flex items-center space-x-2 text-gray-400">
                                                                        <CloudArrowUpIcon className="w-6 h-6" />
                                                                        <span class="font-medium">
                                                                            Sleep je Excel-bestand hierheen of&nbsp;
                                                                            <span class="text-indigo-600">selecteer</span>.
                                                                        </span>
                                                                    </span>
                                                                    <input
                                                                        type="file"
                                                                        name="excel"
                                                                        accept=".xlsx"
                                                                        onChange={(e) => handleExcelUpload(e)}
                                                                        ref={excelUpload}
                                                                        className="hidden"
                                                                    />
                                                                </label>
                                                            ) : (
                                                                <p
                                                                    className="text-md text-gray-500"
                                                                >
                                                                    Je hebt een bestand geupload met {excelRows.length} rijen.
                                                                </p>)}
                                                        </div>


                                                        {excelColumns.length > 0 ?
                                                            <div className='mt-4'>
                                                                <div className='flex mt-1'>
                                                                    <div className='w-1/2'>
                                                                        <p
                                                                            className="text-sm text-gray-500 mt-2 font-bold"
                                                                        >
                                                                            E-mailadres
                                                                        </p>
                                                                    </div>
                                                                    <div className='w-1/2'>
                                                                        <select
                                                                            name="name"
                                                                            id="name"
                                                                            className="w-full px-3 py-2 text-base text-gray-700 placeholder-gray-500 border rounded-lg appearance-none focus:shadow-outline-blue focus:outline-none focus:border-blue-300"
                                                                            onChange={(e) => setExcelEmail(e.target.value)}
                                                                        >
                                                                            <option value="0" selected disabled>Selecteer een kolom</option>
                                                                            {excelColumns ? excelColumns.map((row, index) => (
                                                                                <option key={index} value={index}>{row}</option>
                                                                            )) : null}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className='flex mt-1'>
                                                                    <div className='w-1/2'>
                                                                        <p
                                                                            className="text-sm text-gray-500 mt-2 font-bold"
                                                                        >
                                                                            RSVP
                                                                        </p>
                                                                    </div>
                                                                    <div className='w-1/2'>
                                                                        <select
                                                                            name="rsvp"
                                                                            id="rsvp"
                                                                            className="w-full px-3 py-2 text-base text-gray-700 placeholder-gray-500 border rounded-lg appearance-none focus:shadow-outline-blue focus:outline-none focus:border-blue-300"
                                                                            onChange={(e) => setExcelRSVP(e.target.value)}
                                                                        >
                                                                            <option value="0" selected disabled>Selecteer een kolom</option>
                                                                            {excelColumns ? excelColumns.map((row, index) => (
                                                                                <option key={index} value={index}>{row}</option>
                                                                            )) : null}
                                                                        </select>
                                                                    </div>
                                                                </div>

                                                                <div className='flex mt-1'>
                                                                    <div className='w-1/2'>
                                                                        <p
                                                                            className="text-sm text-gray-500 mt-2 font-bold"
                                                                        >
                                                                            Contract referentie
                                                                        </p>
                                                                    </div>
                                                                    <div className='w-1/2'>
                                                                        <select
                                                                            name="name"
                                                                            id="name"
                                                                            className="w-full px-3 py-2 text-base text-gray-700 placeholder-gray-500 border rounded-lg appearance-none focus:shadow-outline-blue focus:outline-none focus:border-blue-300"
                                                                            onChange={(e) => setExcelContract(e.target.value)}
                                                                        >
                                                                            <option value="0" selected disabled>Selecteer een kolom</option>

                                                                            {excelColumns ? excelColumns.map((row, index) => (
                                                                                <option key={index} value={index}>{row}</option>
                                                                            )) : null}
                                                                        </select>
                                                                    </div>
                                                                </div>

                                                                <div className='flex mt-1'>
                                                                    <div className='w-1/2'>
                                                                        <p
                                                                            className="text-sm text-gray-500 mt-2 font-bold"
                                                                        >
                                                                            Aantal aandelen
                                                                        </p>
                                                                    </div>
                                                                    <div className='w-1/2'>
                                                                        <select
                                                                            name="name"
                                                                            id="name"
                                                                            className="w-full px-3 py-2 text-base text-gray-700 placeholder-gray-500 border rounded-lg appearance-none focus:shadow-outline-blue focus:outline-none focus:border-blue-300"
                                                                            onChange={(e) => setExcelShares(e.target.value)}
                                                                        >
                                                                            <option value="0" selected disabled>Selecteer een kolom</option>

                                                                            {excelColumns ? excelColumns.map((row, index) => (
                                                                                <option key={index} value={index}>{row}</option>
                                                                            )) : null}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : null}
                                                    </div>
                                                    :
                                                    <div>
                                                        <div className='mt-2'>
                                                            <p className="text-sm text-gray-500">
                                                                Er zijn enkele fouten gevonden bij het uitnodigen van de gebruikers.<br />
                                                                <br />
                                                                Probeer het opnieuw met een bestand waar geen fouten in zitten.
                                                                Controleer of de kolommen die je hebt geselecteerd wel bestaan in het bestand en of de waardes die je hebt geselecteerd wel overeenkomen met de waardes in het bestand.
                                                                Controleer ook de genodigdenlijst ofdat de genodigden niet al zijn uitgenodigd.
                                                            </p>
                                                            <ul className='mt-6 text-sm text-gray-400'>
                                                                {errorLogs.map((row, index) => (
                                                                    <li className='mt-2'>{index + 1}. {row}</li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>}
                                            </div>
                                        </div>
                                    </div>
                                    {uploading ?
                                        <div className="mt-2 mb-4">
                                            <Spinner />
                                        </div>
                                        :
                                        <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                            {errorLogs.length == 0 ? <button
                                                type="button"
                                                className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                                                onClick={() => uploadExcel()}
                                            >
                                                Upload
                                            </button> : null}
                                            <button
                                                type="button"
                                                className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                                onClick={() => cancelExcelUpload()}
                                            >
                                                Sluiten
                                            </button>
                                        </div>}
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
            <Transition.Root show={noDocusignContract} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={setNoDocusignContract}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                    <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                                        <button
                                            type="button"
                                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            onClick={() => setNoDocusignContract(false)}
                                        >
                                            <span className="sr-only">Close</span>
                                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                        </button>
                                    </div>
                                    <div className="sm:flex sm:items-start">
                                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                            <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                                        </div>
                                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                            <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                                Contract niet gevonden
                                            </Dialog.Title>
                                            <div className="mt-2">
                                                <p className="text-sm text-gray-500">
                                                    Dit contract werd getekend in de vorige versie van Assemblo en deze uitnodiging werd overgezet via Excel.
                                                    <br /><br />Je vindt dit contract terug in de Airtable van de vorige versie van Assemblo.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                        <button
                                            type="button"
                                            className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                                            onClick={() => setNoDocusignContract(false)}
                                        >
                                            Melding sluiten
                                        </button>

                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </main>
    )
}

const TableComponent = ({ invitees, setInvitees, viewers, signs, props, redirectEnvelope, sendMessage, refreshUser, deleteUserInvite, adminUpdateRSVP, adminUpdateShares }) => {
    const [sortColumn, setSortColumn] = useState('user_attend.firstname');
    const [sortDirection, setSortDirection] = useState('asc');
    const [searchTerm, setSearchTerm] = useState('');

    const handleSort = (column) => {
        if (column === sortColumn) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortColumn(column);
            setSortDirection('asc');
        }
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredInvitees = useMemo(() => {
        const searchTerms = searchTerm.toLowerCase().split(' ').filter(Boolean);

        return invitees.filter(invitee => {
            const fullName = `${invitee.user_attend.firstname.toLowerCase()} ${invitee.user_attend.lastname.toLowerCase()}`;
            const userEmail = invitee.user_attend.email.toLowerCase();
            const userPhone = invitee.user_attend.telephone.toLowerCase();

            return searchTerms.every(term =>
                fullName.includes(term) ||
                userEmail.includes(term) ||
                userPhone.includes(term)
            );
        });
    }, [invitees, searchTerm]);

    const sortedInvitees = useMemo(() => {
        const sorted = [...filteredInvitees].sort((a, b) => {
            if (a[sortColumn] < b[sortColumn]) return sortDirection === 'asc' ? -1 : 1;
            if (a[sortColumn] > b[sortColumn]) return sortDirection === 'asc' ? 1 : -1;
            return 0;
        });
        return sorted;
    }, [filteredInvitees, sortColumn, sortDirection]);

    function updateShares(data, attend_id, save) {
        setInvitees(invitees => invitees.map(invitee => {
            if (invitee.id == attend_id) {
                invitee.shares = data;
            }
            return invitee;
        }
        ));
        if (save) {
            adminUpdateShares(attend_id, data);
        }
    }

    return (
        <div className="">
            <div className="mt-8 flex flex-col">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        <div className="overflow-x-hidden overflow-y-visible relative shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                            <div className="p-4">
                                <input
                                    type="text"
                                    placeholder="Zoeken..."
                                    value={searchTerm}
                                    onChange={handleSearch}
                                    className="w-full border border-gray-300 rounded-md p-2"
                                />
                            </div>
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-100">
                                    <tr>
                                        <th
                                            scope="col"
                                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 cursor-pointer"
                                            onClick={() => handleSort('user_attend.firstname')}
                                        >
                                            Naam {sortColumn === 'user_attend.firstname' && (sortDirection === 'asc' ? '↑' : '↓')}
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                                            onClick={() => handleSort('rsvp')}
                                        >
                                            Status {sortColumn === 'rsvp' && (sortDirection === 'asc' ? '↑' : '↓')}
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                                            onClick={() => handleSort('user_attend.email')}
                                        >
                                            E-mail {sortColumn === 'user_attend.email' && (sortDirection === 'asc' ? '↑' : '↓')}
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                                            onClick={() => handleSort('user_attend.telephone')}
                                        >
                                            Telefoon {sortColumn === 'user_attend.telephone' && (sortDirection === 'asc' ? '↑' : '↓')}
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                                            onClick={() => handleSort('user_attend.lang')}
                                        >
                                            Taal {sortColumn === 'user_attend.lang' && (sortDirection === 'asc' ? '↑' : '↓')}
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                                            onClick={() => handleSort('rsvp')}
                                        >
                                            Hoe {sortColumn === 'rsvp' && (sortDirection === 'asc' ? '↑' : '↓')}
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                                            onClick={() => handleSort('shares')}
                                        >
                                            Aandelen {sortColumn === 'shares' && (sortDirection === 'asc' ? '↑' : '↓')}
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Contract(en)
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Opmerking<br />
                                            <span className="text-xs text-gray-400 font-thin">
                                                Voer geen persoonlijke gegevens in.
                                            </span>
                                        </th>
                                        <th scope="col" className="relative py-3.5 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                            Acties
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white">
                                    {sortedInvitees.map((invitee, inviteeIdx) => (
                                        <tr key={invitee.id} className={inviteeIdx % 2 === 0 ? undefined : 'bg-gray-50'}>
                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                {invitee.user_attend.firstname} {invitee.user_attend.lastname}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                {invitee.rsvp === "online" ? (
                                                    <span className={`inline-block flex-shrink-0 rounded-full px-2 py-0.5 text-xs font-medium ${viewers.includes(invitee.user_attend.id.toString()) ? "text-green-800 bg-green-100" : "text-red-800 bg-red-100"}`}>
                                                        {viewers.includes(invitee.user_attend.id.toString()) ? "ONLINE" : "OFFLINE"}
                                                    </span>
                                                ) : (
                                                    <span className="inline-block flex-shrink-0 rounded-full px-2 py-0.5 text-xs font-medium text-gray-800 bg-gray-100">
                                                        VOLGT NIET ONLINE
                                                    </span>
                                                )}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{invitee.user_attend.email}</td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{invitee.user_attend.telephone}</td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                {invitee.user_attend.lang === "nl" ? "Nederlands" : invitee.user_attend.lang === "fr" ? "Frans" : invitee.user_attend.lang === "en" ? "Engels" : "Onbekend"}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                {invitee.rsvp === "present" ? "Fysiek aanwezig" :
                                                    invitee.rsvp === "not" ? "Afwezig" :
                                                        invitee.rsvp === "proxy" ? "Volmacht" :
                                                            invitee.rsvp === "online" ? "Online volgen" : "Niet doorgegeven"}
                                            </td>
                                            <td className="whitespace-nowrap px-1 py-4 text-sm text-gray-500">
                                                <input
                                                    className="w-full border-none bg-transparent rounded-md"
                                                    type="text"
                                                    value={invitee.shares ? invitee.shares : "/"}
                                                    onChange={(e) => updateShares(e.target.value, invitee.id, false)}
                                                    onBlur={(e) => updateShares(e.target.value, invitee.id, true)}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            e.target.blur();
                                                        }
                                                    }}
                                                />
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                {signs.map(sign => (
                                                    sign.signage_attend.id === invitee.id ? (
                                                        <div
                                                            key={sign.id}
                                                            className='flex pb-2 cursor-pointer underline'
                                                            onClick={() => { redirectEnvelope(sign.envelope_id, invitee.user_attend.firstname, invitee.user_attend.lastname) }}
                                                        >
                                                            <DocumentMagnifyingGlassIcon className="h-5 w-5 text-gray-700" aria-hidden="true" />
                                                            <p>{sign.contract_signage.name}</p>
                                                        </div>
                                                    ) : null
                                                ))}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                <textarea
                                                    type="text"
                                                    rows={2}
                                                    className="w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                    value={invitee.comment}
                                                    onChange={(e) => { sendMessage(e.target.value, invitee.id, false) }}
                                                    onBlur={(e) => { sendMessage(e.target.value, invitee.id, true) }}
                                                />
                                            </td>
                                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                <Menu as="div" className="relative inline-block text-left">
                                                    <div>
                                                        <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                                                            Acties
                                                            <ChevronDownIcon className="-mr-1 ml-2 h-4 w-4" aria-hidden="true" />
                                                        </Menu.Button>
                                                    </div>

                                                    <Transition
                                                        as={React.Fragment}
                                                        enter="transition ease-out duration-100"
                                                        enterFrom="transform opacity-0 scale-95"
                                                        enterTo="transform opacity-100 scale-100"
                                                        leave="transition ease-in duration-75"
                                                        leaveFrom="transform opacity-100 scale-100"
                                                        leaveTo="transform opacity-0 scale-95"
                                                    >
                                                        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                            <div className="py-1">
                                                                <Menu.Item>
                                                                    {({ active }) => (
                                                                        <p
                                                                            onClick={() => refreshUser(invitee.user_attend.id)}
                                                                            className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'} group flex items-center px-4 py-2 text-sm cursor-pointer`}
                                                                        >
                                                                            <ArrowPathIcon
                                                                                className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                                                                aria-hidden="true"
                                                                            />
                                                                            Browser refreshen
                                                                        </p>
                                                                    )}
                                                                </Menu.Item>
                                                                <Menu.Item>
                                                                    {({ active }) => (
                                                                        <p
                                                                            onClick={() => adminUpdateRSVP(invitee.user_attend.id, "present")}
                                                                            className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'} group flex items-center px-4 py-2 text-sm cursor-pointer`}
                                                                        >
                                                                            <RectangleGroupIcon
                                                                                className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                                                                aria-hidden="true"
                                                                            />
                                                                            RSVP: Aanwezig
                                                                        </p>
                                                                    )}
                                                                </Menu.Item>
                                                                <Menu.Item>
                                                                    {({ active }) => (
                                                                        <p
                                                                            onClick={() => adminUpdateRSVP(invitee.user_attend.id, "not")}
                                                                            className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'} group flex items-center px-4 py-2 text-sm cursor-pointer`}
                                                                        >
                                                                            <RectangleGroupIcon
                                                                                className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                                                                aria-hidden="true"
                                                                            />
                                                                            RSVP: Afwezig
                                                                        </p>
                                                                    )}
                                                                </Menu.Item>
                                                                <Menu.Item>
                                                                    {({ active }) => (
                                                                        <p
                                                                            onClick={() => adminUpdateRSVP(invitee.user_attend.id, "proxy")}
                                                                            className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'} group flex items-center px-4 py-2 text-sm cursor-pointer`}
                                                                        >
                                                                            <RectangleGroupIcon
                                                                                className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                                                                aria-hidden="true"
                                                                            />
                                                                            RSVP: Volmacht
                                                                        </p>
                                                                    )}
                                                                </Menu.Item>
                                                                <Menu.Item>
                                                                    {({ active }) => (
                                                                        <p
                                                                            onClick={() => adminUpdateRSVP(invitee.user_attend.id, "online")}
                                                                            className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'} group flex items-center px-4 py-2 text-sm cursor-pointer`}
                                                                        >
                                                                            <RectangleGroupIcon
                                                                                className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                                                                aria-hidden="true"
                                                                            />
                                                                            RSVP: Online
                                                                        </p>
                                                                    )}
                                                                </Menu.Item>
                                                                <Menu.Item>
                                                                    {({ active }) => (
                                                                        <p
                                                                            onClick={() => deleteUserInvite(invitee.id)}
                                                                            className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'} group flex items-center px-4 py-2 text-sm cursor-pointer`}
                                                                        >
                                                                            <TrashIcon
                                                                                className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                                                                aria-hidden="true"
                                                                            />
                                                                            Verwijderen
                                                                        </p>
                                                                    )}
                                                                </Menu.Item>
                                                            </div>
                                                        </Menu.Items>
                                                    </Transition>
                                                </Menu>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};